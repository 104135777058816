import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";

import { ReactComponent as Icon1 } from "../../Assets/svg/user-plus-solid.svg";
import { ReactComponent as Icon2 } from "../../Assets/svg/users-solid.svg";
import { ReactComponent as Icon3 } from "../../Assets/svg/square-plus-solid.svg";
import { ReactComponent as Icon4 } from "../../Assets/svg/briefcase-solid.svg";
import { ReactComponent as Icon5 } from "../../Assets/svg/user-tie-solid.svg";
import { ReactComponent as Icon6 } from "../../Assets/svg/user-gear-solid.svg";
import { ReactComponent as Icon7 } from "../../Assets/svg/key-solid.svg";
import { ReactComponent as Icon8 } from "../../Assets/svg/screwdriver-wrench-solid.svg";
import WordSpan from "../../Components/WordSpan";
import { Hover } from "../../Components/Styles/GlobalStyle.css";
import { useAuth } from "../../app-context/auth-user-context";
import handleErrMsg from "../../Utils/error-handler";
import { CommetLoading, OribitalLoading, ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";
import genericController from "../../controller/generic-controller";

const StaffDashboard = () => {
	const navigate = useNavigate();

	// for reported post
	const [reportedPostCount, setReportedPostCount] = useState(0);
	const [reportedPostCountRequest, setReportedPostCountRequest] = useState(true);

	// for unverified email
	const [unverifiedMailCount, setUnverifiedMailCount] = useState(0);
	const [unverifiedMailCountRequest, setUnverifiedMailCountRequest] = useState(true);

	// for new post
	const [newPostCount, setNewPostCount] = useState(0);
	const [newPostCountRequest, setNewPostCountRequest] = useState(true);

	// for active staff
	const [activeStaffCount, setActiveStaffCount] = useState(0);
	const [activeStaffCountRequest, setActiveStaffCountRequest] = useState(true);

	const { authUser, handleRefresh, logout } = useAuth();
	const user = authUser();

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 564 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

    useEffect( () => {
		initialize();
    }, []);

	const initialize = async () => {
		try {
            const urls = [ '/staff/active-staff', '/jobs/flagged-jobs/count', '/staff/unverified-mails', '/jobs/new/count'];
            const response = await genericController.performGetRequests(urls);
            const { 0: activeStaff, 1: flaggedPosts, 2: unverifiedMails, 3: newJobs } = response;

            //check if the request to fetch active staff doesn't fail before setting values to display
            if(activeStaff){
				setActiveStaffCountRequest(false);
                setActiveStaffCount(activeStaff.data);
            }

            //check if the request to fetch flagged post doesn't fail before setting values to display
            if(flaggedPosts){
				setReportedPostCountRequest(false);
                setReportedPostCount(flaggedPosts.data);
            }

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(unverifiedMails){
				setUnverifiedMailCountRequest(false);
                setUnverifiedMailCount(unverifiedMails.data);
            }

            //check if the request to fetch new posts doesn't fail before setting values to display
            if(unverifiedMails){
				setNewPostCountRequest(false);
                setNewPostCount(newJobs.data);
            }
		} catch (error) {
			// Incase of 408 Timeout error (Token Expiration), perform refresh
			try {
				if(error.response?.status === 408){
					await handleRefresh();
					return initialize();
				}
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/404');
				}
				// display error message
				toast.error(handleErrMsg(error).msg);
			} catch (error) {
				// if error while refreshing, logout and delete all cookies
				logout();
			}
		}
	};

	const rowClicked = (title) => {
		switch (title) {
			case "New posts":
				navigate('staff/jobs');
				break;
			case "Unverified emails":
				navigate('emails/unverified');
				break;
			case "Reported Posts":
				navigate('/jobs/reported');
				break;
		
			default:
				break;
		}
	}

	const buildQuickMenu = () => {
		return <div className="container mt-4">
			<div className="row g-2">
				{[
					{ title: "Add User", IconSrc: Icon1, link: "add-user", desc: 'Create or add new users', bg: 'bg-success-subtle' },
					{ title: "View Staff", IconSrc: Icon2, link: "all-staff", desc: 'View active and non active staff', bg: 'bg-primary-subtle' },
					{ title: "Post a Job", IconSrc: Icon3, link: "post-a-job", desc: 'Post a job on behalf of a company', bg: 'bg-secondary-subtle' },
					{ title: "Job Seekers", IconSrc: Icon5, link: "seekers", desc: 'View all registered job seekers', bg: 'bg-danger-subtle' },
					{ title: "Employers", IconSrc: Icon4, link: "employers", desc: 'View all registered employers', bg: 'bg-warning-subtle' },
					{ title: "Change Password", IconSrc: Icon7, link: "change-pw", desc: 'Change your password', bg: 'bg-info-subtle' },
					{ title: "Settings", IconSrc: Icon8, link: "staff/settings", desc: 'View the settings page', bg: 'bg-primary-subtle' },
					{ title: "Edit Profile", IconSrc: Icon6, link: "staff/edit-profile", desc: 'Edit your profile and make necessary changes', bg: 'bg-success-subtle' },
				].map(({ title, IconSrc, link, desc, bg }, idx) => (
					<div className="col-md-4 col-sm-12">
						<div key={idx} className="btn p-3 border w-100" onClick={() => navigate(link)} style={{minHeight: '110px'}}>
							<div className="d-flex align-items-center gap-3">
								<div className={`p-2 ${bg} text-center rounded-3 onHover`} style={{boxShadow: 'black 3px 2px 5px'}}>
									<IconSrc width={50} height={40} />
								</div>
								<div className="d-flex flex-column text-start">
									<span className="fs-6 noto-sans-font fw-bold">{title}</span>
									<span className="text-muted">{desc}</span>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	}

	return (
		<div className="py-2 py-md-4">
			<div className="container-md">
				<h1 className="fw-bold mt-4">
					Hello{" "}
					<WordSpan>
						<span className="bungee-regular"> {user.firstName} </span>
					</WordSpan>
				</h1>
				{/* SHOWS ON xs SCREEN SIZE */}
				<div className="row d-md-none my-3 mx-3">
					<Carousel
						responsive={responsive}
						infinite={true}
						autoPlay={true}
						autoPlaySpeed={4000}
						customTransition="all 1s"
						transitionDuration={500}
					>
						{[
							{ 
								color: "secondary-subtle", 
								title: "New posts", 
								number: newPostCountRequest ? <CommetLoading color="#000000" size="small" /> : newPostCount 
							},
							{
								color: "primary-subtle",
								title: "Unverified emails",
								number: unverifiedMailCountRequest ? <OribitalLoading color="#000000" size="small" variant = "track-disc" /> : unverifiedMailCount,
							},
							{
								color: "success-subtle",
								title: "Reported Posts",
								number: reportedPostCountRequest ? <ThreeDotLoading color="#000000" size="large" variant = "windmill" /> : reportedPostCount,
							},
							{ 
								color: "danger-subtle", 
								title: "Active staff", 
								number: activeStaffCountRequest ? <ThreeDotLoading color="#000000" size="large" variant = "pulsate" /> : activeStaffCount 
							},
						].map(({ color, title, number }, idx) => (
							<div key={idx} className="btn col-12 col-sm-6 col-lg-3 w-100" onClick={() => rowClicked(title)} >
								<div className="mx-2">
									<div
										className={`rounded-5 circle border bg-${color} bg-gradient d-flex flex-column align-items-center justify-content-evenly p-2`}
										style={{ height: "12rem" }}
									>
										<h1 className="fw-bold">{number}</h1>
										<h4
											className="bebas-neue-regular p-1"
											style={{ letterSpacing: "0.10em" }}
										>
											{title}
										</h4>
									</div>
								</div>
							</div>
						))}
					</Carousel>
				</div>

				{/* SHOWS ON md SCREEN SIZE */}
				<div className="d-none d-md-block my-3">
					<div className="row g-2 gx-4">
						{[
							{ 
								color: "secondary-subtle", 
								title: "New posts", 
								number: newPostCountRequest ? <CommetLoading color="#000000" size="small" /> : newPostCount 
							},
							{
								color: "primary-subtle",
								title: "Unverified emails",
								number: unverifiedMailCountRequest ? <OribitalLoading color="#000000" size="small" variant = "track-disc" /> : unverifiedMailCount,
							},
							{
								color: "success-subtle",
								title: "Reported Posts",
								number: reportedPostCountRequest ? <ThreeDotLoading color="#000000" size="large" variant = "windmill" /> : reportedPostCount,
							},
							{ 
								color: "danger-subtle", 
								title: "Active staff", 
								number: activeStaffCountRequest ? <ThreeDotLoading color="#000000" size="large" variant = "pulsate" /> : activeStaffCount 
							},
						].map(({ color, title, number }, idx) => (
							<div key={idx} className="btn col-12 col-sm-6 col-lg-3" onClick={() => rowClicked(title)} >
								<div
									className={`rounded-5 circle border bg-${color} bg-gradient d-flex flex-column align-items-center justify-content-evenly p-2`}
									style={{ height: "12rem" }}
								>
									<h1 className="fw-bold">{number}</h1>
									<h4
										className="bebas-neue-regular p-1"
										style={{ letterSpacing: "0.10em" }}
									>
										{title}
									</h4>
								</div>
							</div>
						))}
						{/* </Carousel> */}
					</div>
				</div>

				<h3 className="mt-5 fw-bold noto-sans-font">
					Quick<WordSpan color={"lightBlue"}> Menu</WordSpan>
				</h3>
				<hr />
				{buildQuickMenu()}
			</div>
		</div>
	);
};

export default StaffDashboard;
