import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { format } from "date-fns";
import Select from "react-select";

import { Hover, MyContainer } from "../../Components/Styles/GlobalStyle.css";
import handleErrMsg from "../../Utils/error-handler";
import { useAuth } from "../../app-context/auth-user-context";
import staffController from "../../controller/staff-controller";
import DataPagination from "../../Components/DataPagination";
import { ThreeDotLoading } from "../../Components/react-loading-indicators/Indicator";

const ViewAllStaffPage = () => {
    const navigate = useNavigate();

    const { authUser, handleRefresh, logout } = useAuth();
    const user = authUser();
    // for pagination
    const [currentPage] = useState(1);
    //  data returned from DataPagination
    const [pagedData, setPagedData] = useState([]);

    const [allStaff, setAllStaff] = useState([]);
    const [filteredStaff, setFilteredStaff] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
	const [networkRequest, setNetworkRequest] = useState(true);

    useEffect( () => {
        // fetch users
		if(!user || user.accType){
			navigate('/dashboard');
		}
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await staffController.findAll();

            //check if the request to fetch indstries doesn't fail before setting values to display
            if(response && response.data){
				setFilteredStaff(response.data);
				setAllStaff(response.data);
                setStaffOptions(response.data.map( staff => ({label: `${staff.fname} ${staff.lname}`, value: staff.id})));
				setNetworkRequest(false);
            }
        } catch (error) {
            // Incase of 408 Timeout error (Token Expiration), perform refresh
            try {
                if(error.response?.status === 408){
                  await handleRefresh();
                  return fetchUsers();
                }
				// Incase of 401 Unauthorized, navigate to 404
				if(error.response?.status === 401){
					navigate('/dashboard');
				}
                toast.error(handleErrMsg(error).msg);
            } catch (error) {
                // if error while refreshing, logout and delete all cookies
                logout();
            }
        }
    };

    const pageChanged = (paginatedData) => {
        setPagedData(paginatedData);
    };

	const staffSelected = (staff) => {
		console.log(staff);
		if(!staff){
			// react-select is cleared, in which case load all staff
			setFilteredStaff(allStaff);
			return;
		}
		const filtered = allStaff.find(s => s.id === staff.value);
		setFilteredStaff([filtered]);
	};

	const buildCardItem = (staff, i) => {
		const initials = Array.from(staff.fname)[0] + Array.from(staff.lname)[0];
		return <Hover key={i} className="col-md-4 col-sm-12">
			<Link to={`${initials}/${staff.id}`} target="_blank" rel="noopener noreferrer"
				className="btn text-start px-3 border border-2 rounded-3 p-2 active w-100"
				// style={{ minWidth: "24rem" }}
			>
				<div className="row">
					<div className="col-2">
						<span className="border rounded-circle bg-light shadow-sm p-1 fw-bold noto-sans-font">
							{Array.from(staff.fname)[0] + Array.from(staff.lname)[0]}
						</span>
					</div>
					<div className="col-7">
						<p className="overflow-ellipsis fw-bold m-1">{ staff.fname }</p>
						<p className="overflow-ellipsis m-1">{ staff.lname }</p>
						<p className="overflow-ellipsis m-1">{ staff.phone }</p>
						<p className="fw-bold text-success m-1 overflow-ellipsis">{ staff.email }</p>
						<small className="text-muted fw-bold m-1">
							Created: {format(staff.createdAt, "MM/dd/yyyy")}
						</small>
					</div>
					<div className="col-3">
						<Badge
							className={`ms-auto text-secondary fw-bold align-self-start ${
								staff.status ? "bg-primary-subtle" : "bg-danger-subtle"
							}`}
						>
							<span>{staff.status ? "Active" : "Inactive"}</span>
						</Badge>
					</div>
				</div>
			</Link>
		</Hover>
	};

    const buildStaffCards = pagedData.map((staff, i) => { return buildCardItem(staff, i) });

	return (
		<>
			<MyContainer height="100%" $padding_y="20px">
				<div className="container-md">
					<div className="container">
						<div className="row gap-3">
							<Row className="col-md-6 col-12 align-items-center">
								<Col sm="8">
									<Select
										classNamePrefix="staff"
										isLoading={networkRequest}
										isClearable={true}
										isSearchable={true}
										name="staff"
										onChange={ val =>  staffSelected(val) }
										options={staffOptions}
										noOptionsMessage={({ inputValue }) => `No result found for "${inputValue}"`}
									/>
								</Col>
							</Row>
							<div className="col-md-6 col-12 d-flex justify-content-cente">
								<DataPagination itemCount={filteredStaff ? filteredStaff.length : 0} pageSize={10} pageChanged={pageChanged} data={filteredStaff}
									pageNumber={currentPage} />
							</div>
						</div>
					</div>
					<div className="container mt-4">
						<div class="row g-3">
							{ networkRequest && <ThreeDotLoading variant="pulsate" color="#f78419" size="large" /> }
							{buildStaffCards}
						</div>
					</div>
				</div>
			</MyContainer>
		</>
	);
};
export default ViewAllStaffPage;
